import React, { Component } from "react";

import Footer from "./footer";
import Sideconnect from "./sideconnect";

import "../styles/index.scss";


class Layout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isConnectRibbonOpen: false,
    };

    this.toggleShowRibbon = this.toggleShowRibbon.bind(this);
  }

  toggleShowRibbon() {
    this.setState(
      {
        isConnectRibbonOpen: !this.state.isConnectRibbonOpen,
      },
      () => {
        // console.log("test: " + this.state.isConnectRibbonOpen);
      }
    );
  }

  render() {
    return (
      <div>
        <Sideconnect
          toggleShowRibbon={this.toggleShowRibbon}
          isConnectRibbonOpen={this.state.isConnectRibbonOpen}
        />

        <div>{this.props.children}</div>
        <Footer />
      </div>
    );
  }
}

export default Layout;
