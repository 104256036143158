import React from "react";

const Introduction = () => {
  return (
    <div className="section-center-wrap">
      <div className="box-intro">
        <h2>Rickvian aldi</h2>

        <h2>
          Software Engineer <br />
          (Front-end)
        </h2>

        <p>
          A Software engineer who loves to have pixels aligned and loves
          impactful business aspects.
        </p>

        <p>
          Delivering "Solution" with "Elegance", in the sweet spot balance
          between "Get done" and "Get it perfect".
        </p>

        <p>Connect with me via link beside.</p>
      </div>
    </div>
  );
};

export default Introduction;
