import React from "react";
import Section from "../../components/Section";

const Connect = () => {
  return (
    <Section id="connect" borderColor="orange" title="Connect">
      <div className="text-center">
        <h3>Contact me via link beside</h3>
        <div>Made with 💻 from 🇮🇩 </div>
      </div>
    </Section>
  );
};

export default Connect;
