import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

const Sideconnect = (props) => {
  return (
    <>
      <div
        className={`connectRibbon ${props.isConnectRibbonOpen ? "isOpen" : ""}`}
        onClick={props.toggleShowRibbon}
      >
        {!props.isConnectRibbonOpen ? (
          <FontAwesomeIcon icon={faEnvelope} />
        ) : (
          <span className="connectRibbonCloseButton">X</span>
        )}
      </div>

      <div
        className={`horizontal-socialbar-container ${
          props.isConnectRibbonOpen ? "socialBarOpen" : ""
        }`}
      >
        <div className="white-border"></div>
        <div className="rotation-wrapper-outer">
          <div className="rotation-wrapper-inner">
            <div className="element-to-rotate">Connect</div>
          </div>
        </div>

        <a href="https://www.linkedin.com/in/rickvianaldi/">
          <div className="whitebar-socialmedia-button text-center">
            <FontAwesomeIcon icon={faLinkedin} />
          </div>
        </a>
        <a href="https://github.com/rickvian">
          <div className="whitebar-socialmedia-button text-center">
            <FontAwesomeIcon icon={faGithub} />
          </div>
        </a>

        <div className="white-border"></div>
      </div>
    </>
  );
};

export default Sideconnect;
