import React from "react";
import Section from "../../components/Section";

const ProjectLink = ({ title, url }) => (
  <div style={{ marginBottom: "10px" }}>
    <a rel="noreferrer" href={url} target="_blank">
      {title}
    </a>
  </div>
);
const ProjectList = ({ projects, title }) => (
  <div className="box-inner-transparent textShadow">
    <h2>{title}</h2>
    {projects.map((project, index) => (
      <ProjectLink key={index} title={project.title} url={project.url} />
    ))}
  </div>
);

const professionalProjects = [
  { title: "UniPin Front End V5", url: "https://www.unipin.com/" },
  { title: "Corp UniPin Web slicing", url: "https://corp.unipin.com/" },
  {
    title: "SEACA 2019 Event Site development",
    url: "https://www.unipin.com/seaca2019",
  },
  {
    title: "Theiconomics.com UI and front end development (Wordpress)",
    url: "https://www.theiconomics.com/",
  },
  {
    title: "Edumor.com web Front End Development",
    url: "https://www.edumor.com/",
  },
  {
    title: "Fabula.co.id (UI Development)",
    url: "https://www.behance.net/gallery/73050053/Fabula-Front-end-work",
  },
  {
    title: "ISTB Website design and development",
    url: "https://www.behance.net/gallery/22184767/STMIK-ISTB-web-design",
  },
];

const personalProjects = [
  {
    title: "Pokemon App - React - GraphQl",
    url: "https://pokemonrickvian.netlify.app/",
  },
  {
    title: "Burger Order Web App - React Redux",
    url: "https://burgerapprickvian.netlify.app/",
  },
  { title: "Codepen Collections", url: "https://codepen.io/collection/xKbOVv" },
];

const Projects = () => {
  return (
    <>
      <Section id="portfolio" borderColor="orange" title="Projects">
        <div className="box-inner-transparent textShadow">
          <ProjectList
            projects={professionalProjects}
            title="Professional Projects"
          />
          <ProjectList projects={personalProjects} title="Personal Projects" />
        </div>
      </Section>
    </>
  );
};

export default Projects;
