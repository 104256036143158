import React from "react";
import Section from "../../components/Section";

const Skills = () => {
  return (
    <Section id="skills" title={`Skills & Stacks`} borderColor="blue">
      <div className="text-center textShadow">
        <div> Click to see details</div>
        <iframe
          className="iframe-tech"
          data-layers="2,3,4"
          data-stack-embed="true"
          src="https://embed.stackshare.io/stacks/embed/18773352776ee05c621d81f35c2138"
        ></iframe>
      </div>
    </Section>
  );
};

export default Skills;
