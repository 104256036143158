import React, { useEffect } from "react";
import Gradient from "../GradientSrc/Gradient.js";

const GradientCanvas = () => {
  useEffect(() => {
    new Gradient({
      canvas: "#gradient-canvas",
      colors: ["#a960ee", "#eb7521", "#90e0ff", "#ffcb57"],
    });
  }, []);

  return <canvas id="gradient-canvas" />;
};

export default GradientCanvas;
