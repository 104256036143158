import React from "react";
import Section from "../../components/Section";

const Certificates = () => {
  return (
    <Section id="certificates" borderColor="purple" title="Extras">
      <div className="text-center textShadow">
        <h3>Event Participations</h3>

        <div>
          <a
            href="https://drive.google.com/drive/folders/18NebSdnioMTr0pDRYyxVTSQ7fqiCyOnZ?usp=sharing"
            target="_blank"
            rel="noopener noreferrer"
          >
            Event Participations.
          </a>
        </div>

        <h3>Qualifications</h3>

        <div>
          <a
            href="https://drive.google.com/drive/folders/1iMy_nKunLM1qPGUa8u6yprNJyiwWlc-c?usp=sharing"
            target="_blank"
            rel="noopener noreferrer"
          >
            Course / Qualifications
          </a>
        </div>
      </div>
    </Section>
  );
};

export default Certificates;
